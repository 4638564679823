export default Page;

import React from "react";
import { IconError404 } from "@tabler/icons-react";
import { usePageContext } from "../../renderer/usePageContext";
import { Button, Stack, Text, ThemeIcon, Title, rem } from "@mantine/core";

import "@mantine/core/styles/ThemeIcon.css";
import "@mantine/core/styles/Stack.css";

function Page() {
  const ctx = usePageContext();
  let { is404, abortReason } = ctx;
  if (!abortReason) {
    abortReason = is404 ? "Page not found." : "Something went wrong.";
  }
  return (
    <Center>
      <ThemeIcon
        size={120}
        radius="md"
        variant="transparent"
        c="var(--mantine-color-gray-4)"
      >
        <IconError404 size={120} />
      </ThemeIcon>

      <Stack justify="center" align="center" mt={30}>
        <Title>{abortReason}</Title>
        <Text c="dimmed" maw={rem("540px")} ta="center" size="lg" fw={400}>
          Page you are trying to open does not exist. You may have mistyped the
          address, or the page has been moved to another URL. If you think this
          is an error contact support.
        </Text>

        <Button mt="md" component="a" href="/">
          Take me back to home page
        </Button>
      </Stack>
    </Center>
  );
}

function Center({ style, ...props }: any) {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      {...props}
    ></div>
  );
}
